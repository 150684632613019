<template>
  <div class="change-king-tag-box" v-if="showPopup">
    <a-modal
        :width="1000"
        v-model="showPopup"
        title="类型检测"
    >
      <template #footer><div></div></template>
      <div class="flex-center-center position-box" :style="{ width: width + 'px', height: height + 'px' }">
        <img :style="{ width: width + 'px', height: height + 'px' }" :src="imageUrl" alt="">
        <div
            class="position-border"
            v-for="(point, index) in markPoints"
            :key="index"
            :style="computedScalePx(point)"
        >
          <span class="border-left-top">概率：<span class="color-red">{{ point.score }}</span></span>
          <span class="border-right-bottom"><span class="color-red">类型：{{ point.classNameDesc }}</span></span>
        </div>
      </div>
    </a-modal>
  </div>
</template>
<script>
import {getTempImgInfoApi} from "@/views/cmsPage/productManage/auctionAboutList/_apis"
import {getUrlBeforeQuestionMark} from "@/untils/otherEvent"

export default {
  data() {
    return {
      // 缩放比例
      scaleDivide: 1,
      width: 900,
      height: 0,
      showPopup: false,
      yuanImg: '',
      imageUrl: '',
      imageWidth: '',
      imageHeight: '',
      markPoints: [],
      widthDivideHeight: 1,
    }
  },
  computed: {
    computedScalePx() {
      return (point) => {
        return `
              top: ${point.y / this.scaleDivide}px;
              left: ${point.x / this.scaleDivide}px;
              width: ${point.width / this.scaleDivide}px;
              height: ${point.height / this.scaleDivide}px;`
      }
    }
  },
  methods: {
    /**  显示弹窗 */
    async show(cutImg, yuanImg) {
      this.yuanImg = getUrlBeforeQuestionMark(yuanImg, '?')
      await this.getImgInfo()
    },
    /** 请求 */
    async getImgInfo() {
      this.$loading.show()
      const res = await getTempImgInfoApi({itemImg: this.yuanImg})
      this.$loading.hide()
      if (res.status !== '200') return
      this.imageUrl = res.data.imageUrl
      this.imageWidth = res.data.width
      this.imageHeight = res.data.height
      this.widthDivideHeight = this.imageWidth / this.imageHeight
      this.height = this.width / this.widthDivideHeight
      this.scaleDivide = this.imageWidth / this.width
      this.markPoints = res.data.recoList
      this.showPopup = true
    },
  }
}
</script>
<style lang="scss" scoped>
.border-right-bottom {
  position: absolute;
  right: 0;
  bottom: 0;
  font-weight: bold;
}
.border-left-top {
  position: absolute;
  left: 0;
  top: 0;
  font-weight: bold;
}
.position-border {
  position: absolute;
  top: 0;
  left: 0;
  width: 100px;
  height: 100px;
  z-index: 100;
  border: 1px solid #fff;
  color: #fff;
}
.position-box {
  position: relative;
  width: 100%;
}
</style>