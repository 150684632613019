var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.showPopup
    ? _c(
        "div",
        { staticClass: "change-king-tag-box" },
        [
          _c(
            "a-modal",
            {
              attrs: { width: 1000, title: "类型检测" },
              scopedSlots: _vm._u(
                [
                  {
                    key: "footer",
                    fn: function() {
                      return [_c("div")]
                    },
                    proxy: true
                  }
                ],
                null,
                false,
                3545152822
              ),
              model: {
                value: _vm.showPopup,
                callback: function($$v) {
                  _vm.showPopup = $$v
                },
                expression: "showPopup"
              }
            },
            [
              _c(
                "div",
                {
                  staticClass: "flex-center-center position-box",
                  style: { width: _vm.width + "px", height: _vm.height + "px" }
                },
                [
                  _c("img", {
                    style: {
                      width: _vm.width + "px",
                      height: _vm.height + "px"
                    },
                    attrs: { src: _vm.imageUrl, alt: "" }
                  }),
                  _vm._l(_vm.markPoints, function(point, index) {
                    return _c(
                      "div",
                      {
                        key: index,
                        staticClass: "position-border",
                        style: _vm.computedScalePx(point)
                      },
                      [
                        _c("span", { staticClass: "border-left-top" }, [
                          _vm._v("概率："),
                          _c("span", { staticClass: "color-red" }, [
                            _vm._v(_vm._s(point.score))
                          ])
                        ]),
                        _c("span", { staticClass: "border-right-bottom" }, [
                          _c("span", { staticClass: "color-red" }, [
                            _vm._v("类型：" + _vm._s(point.classNameDesc))
                          ])
                        ])
                      ]
                    )
                  })
                ],
                2
              )
            ]
          )
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }